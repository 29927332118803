import Layout from "../../components/layout"
import Heading from "../../components/heading"
import HorizontalImage from "../../components/horizontal-image"
import Text from "../../components/text"
import VerticalImage from "../../components/vertical-image"
import VerticalText from "../../components/vertical-text"
import React from 'react';
import { graphql } from "gatsby"

export const query = graphql`
  query {
    photo1: file(relativePath: { eq: "byt-zubateho/zubateho-1.jpg" }) { ...fluidImage }
    photo2: file(relativePath: { eq: "byt-zubateho/zubateho-2.jpg" }) { ...fluidImage }
    photo3: file(relativePath: { eq: "byt-zubateho/zubateho-3.jpg" }) { ...fluidImage }
    photo4: file(relativePath: { eq: "byt-zubateho/zubateho-4.jpg" }) { ...fluidImage }
    photo5: file(relativePath: { eq: "byt-zubateho/zubateho-5.jpg" }) { ...fluidImage }
    photo6: file(relativePath: { eq: "byt-zubateho/zubateho-6.jpg" }) { ...fluidImage }
    photo7: file(relativePath: { eq: "byt-zubateho/zubateho-7.jpg" }) { ...fluidImage }
    photo8: file(relativePath: { eq: "byt-zubateho/zubateho-8.jpg" }) { ...fluidImage }
  }
`
const BytZubatehoPage = ({ data }) => (
  <Layout title="Byt Zubatého">
    <HorizontalImage marginTop borderBottom image={data.photo1.childImageSharp.fluid} />
    <VerticalImage borderRight image={data.photo2.childImageSharp.fluid} />
    <VerticalText>
      <Heading>Podkrovní byt Zubatého 5, Praha 5, 2017</Heading>
      <Text>
        Zadáním bylo navrhnout prostor, který může plnit funkci bytu či ateliéru. V případě potřeby potom vše jednoduše přeměnit na kancelářské prostory. Mou snahou bylo zachovat atmosféru stavající půdy, její vzdušnost se všemi přednostmi i nedokonalostmi. Zvoleno bylo nadkrokevní zateplení aby se zachoval původní krov v celé své podobě. Původní prvky krovu maji být vykartáčovány a opatřeny bilou lazurou. Podlaha je navržena jako betonová stěrka litá mezi vazné trámy které zůstavají v podlaze viditelné. Zachovávám i původní komíny, jeden z nich je využit jako krb + odtah digestoře. Původní podkroví je nově prosvětleno pomocí ateliérových oken, vikýře do vnitrobloku a doplněno o střešní výlezy. Do jednoho z polí plné vazby je vložen blok s kuchyní, za ní je umístěna ložnice se šatnou. V místě původního světlíku je nově navržena koupelna, díky tomu lze z vany sledovat noční oblohu. Kuchyňská linka je rozdělena hřebenem na dvě části, kdy jedna měla být tvořena měděnými deskami. Časem by docházelo k oxidaci těchto desek a pomalé barevné změně kuchyně.
      </Text>
    </VerticalText>
    <HorizontalImage borderBottom borderTop image={data.photo3.childImageSharp.fluid} />
    <HorizontalImage borderBottom image={data.photo4.childImageSharp.fluid} />
    <HorizontalImage borderBottom image={data.photo5.childImageSharp.fluid} />
    <HorizontalImage borderBottom image={data.photo6.childImageSharp.fluid} />
    <HorizontalImage borderBottom image={data.photo7.childImageSharp.fluid} />
    <HorizontalImage image={data.photo8.childImageSharp.fluid} />
  </Layout>
)

export default BytZubatehoPage
